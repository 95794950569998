<template>
  <div>
    <a-layout id="components-layout-demo-side" style="min-height: 100vh">
      <a-layout-sider v-model="collapsed" collapsible>
        <!-- <div class="logo" /> -->
        <div style="text-align: center">
          <img :src="webSetting.detail.webLogo" class="logo" alt="LOGO" />
        </div>

        <a-menu theme="dark" :default-selected-keys="['1']" mode="inline">
          <a-menu-item key="1">
            <router-link key="expand" to="/vip">
              <a-icon type="pie-chart" />
              <span>首页</span>
            </router-link>
          </a-menu-item>

          <a-sub-menu key="2">
            <span slot="title">
              <a-icon type="user" /><span>商城设置</span>
            </span>
            <a-menu-item key="2-1">
              <router-link key="expand" to="/vip/sys"> 基本信息 </router-link>
            </a-menu-item>

            <a-menu-item key="2-2">
              <router-link key="expand" to="/vip/customer">
                客服管理
              </router-link>
            </a-menu-item>

            <a-menu-item key="2-3">
              <router-link key="expand" to="/vip/homeUp">
                首页弹框
              </router-link>
            </a-menu-item>

            <a-menu-item key="2-4">
              <router-link key="expand" to="/vip/template">
                网站装修
              </router-link>
            </a-menu-item>
          </a-sub-menu>

          <a-menu-item key="3">
            <router-link key="expand" to="/vip/product">
              <a-icon type="desktop" />
              <span>商品管理</span>
            </router-link>
          </a-menu-item>

          <a-menu-item key="4">
            <router-link key="expand" to="/vip/order">
              <a-icon type="desktop" />
              <span>订单管理</span>
            </router-link>
          </a-menu-item>

          <a-menu-item key="5">
            <router-link key="expand" to="/vip/user">
              <a-icon type="desktop" />
              <span>用户管理</span>
            </router-link>
          </a-menu-item>

          <a-sub-menu key="6">
            <span slot="title">
              <a-icon type="team" /><span>分站管理</span>
            </span>
            <a-menu-item key="6-1">
              <router-link key="expand" to="/vip/vipManage/vipList">
                分站列表
              </router-link>
            </a-menu-item>

            <a-menu-item key="6-2">
              <router-link key="expand" to="/vip/vipManage/version">
                分站套餐
              </router-link>
            </a-menu-item>
          </a-sub-menu>

          <a-sub-menu key="7">
            <span slot="title">
              <a-icon type="team" /><span>财务管理</span>
            </span>
            <a-menu-item key="7-1">
              <router-link key="expand" to="/vip/commissionDetail">
                佣金明细
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0">
          <div style="float: right; margin: 0px 50px">
            <el-dropdown>
              <a-avatar
                :size="40"
                shape="square"
                :style="{ backgroundColor: '#7265e6', verticalAlign: 'middle' }"
              >
                管理员
              </a-avatar>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span @click="toReturn()">商城首页</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </a-layout-header>

        <a-layout-content style="margin: 0 16px">
          <div
            :style="{
              margin: '16px 0',
              padding: '24px',
              background: '#fff',
              minHeight: '360px',
            }"
          >
            <router-view> </router-view>
          </div>
        </a-layout-content>
        <!-- <a-layout-footer style="text-align: center">
          Ant Design ©2018 Created by Ant UED
        </a-layout-footer> -->
      </a-layout>
    </a-layout>

    <!-- 首页弹框 -->
    <el-dialog
      :destroy-on-colse="true"
      title="温馨提示"
      :visible.sync="vipNoticeVisible"
      append-to-body
    >
      <div class="ql-editor" v-html="vipNoticeContent" />
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getToken } from "@/utils/auth";
import { getVipNotice } from "@/api/index";

export default {
  data() {
    return {
      collapsed: false,

      isCollapse: false,
      breadList: [],
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      vipNoticeVisible: false,
      vipNoticeContent: undefined,
    };
  },
  computed: {
    ...mapState({
      branchId: (state) => state.user.branchId,
      webSetting: (state) => state.index.webSetting,
    }),
  },

  mounted() {
    const isMobile = this.$_isMobile();
    if (isMobile) {
      this.isCollapse = true;
    }
  },
  created() {
    this.verifyIsLogin();

    this.queryVipNotice();
  },
  methods: {
    $_isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    /**
     * 查询首页弹框公告
     */
    queryVipNotice() {
      getVipNotice().then((response) => {
        if (response.result) {
          this.vipNoticeVisible = true;
          this.vipNoticeContent = response.result.content;
        }
      });
    },

    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.$store.dispatch("user/info").then(() => {
          this.$nextTick(() => {
            this.verifyAuthority();
          });
        });
      }
    },

    /**
     * 验证权限
     */
    verifyAuthority() {
      if (!this.branchId) {
        this.$message({
          message: "权限不足",
          type: "warning",
        });
        this.$router.push("/");
      }
    },

    toReturn() {
      this.$router.push("/");
    },

    /**
     * 跳转路由
     */
    toLink(link) {
      this.$router.push(link).catch((err) => {
        // 在这里处理错误
        console.error("路由跳转出错：", err);
      });
    },
  },
};
</script>
<style scoped>
.fz-container {
  width: 100%;
  min-height: 100vh;
}
.el-header {
  font-size: 20px;
  background-color: #ffffff;
  border-color: rgba(25, 39, 142, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-nav-header {
  color: black;
  height: 58px;
  line-height: 58px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.icon-box {
  float: left;
  width: 30px;
  text-align: center;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 230px;
}

.sidebar-title {
  display: inline-block;
  margin: 0;
  color: #000000;
  font-weight: 600;
  line-height: 50px;
  font-size: 20px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
  margin-left: 5px;
}

.sidebar-logo {
  width: 30px;
  height: 30px;
  border-radius: 2px;
}

/* x */
#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
