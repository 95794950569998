<template>
  <div style="background-color: #f5f7fa">
    <van-nav-bar left-text="返回" title="我的订单" left-arrow @click-left="onClickLeft" />
    <van-search v-model="queryParam.keywordSearch" shape="round" show-action placeholder="请输入订单号/充值账号">
      <template #action>
        <div @click="searchAction()">搜索</div>
      </template>
    </van-search>

    <van-tabs type="line" style="display: block" v-model="activeName" @click="changeTab($event)">
      <van-tab :title="item.orderLabel" v-for="(item, key, index) in orderStatusList" :key="index"
        :name="item.statusDelivery">
        <van-list v-model="loadingState" :finished="finished" finished-text="没有更多了" @load="loadMore">
          <div style="padding: 5px 10px 10px 10px">
            <div class="scroll-item" v-for="(v, key, index) in orderLists" :key="index">
              <div class="item-top">
                <div class="order-text">
                  订单号：{{ v.serialNum }}
                  <van-icon name="label" />
                </div>
                <span :style="{ color: v.statusTextColor }" style="font-weight: 700">
                  {{ v.statusText }}
                </span>
              </div>
              <div class="center" @click="toProductDetails(v.orderFormProduct.productId)">
                <van-image width="100" height="100" :src="v.orderFormProduct.picUrl" lazy-load/>

                <div class="center-right">
                  <span class="center-title">
                    <span>{{ v.orderFormProduct.productName }}</span>
                  </span>

                  <div class="center-num">
                    <span class="money" style="font-size: 14px; color: #9a9a9a">单价：￥{{ v.orderFormProduct.productPrice
                      }}</span>

                    <span class="number">
                      <span>数量：{{ v.orderFormProduct.quantity }}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="order-bottom">
                <div class="order-bottom-left">
                  <span style="font-weight: 600">总金额：</span>
                  <span class="money">
                    <span>{{ v.amountFinally }}</span>
                  </span>
                </div>
                <div class="order-bottom-right">
                  <template v-if="v.orderFormDelivery">
                    <van-button type="info" round size="small" @click="getLogisticsInfo(v)">物流信息</van-button>
                  </template>

                  <van-button type="info" round size="small" @click="queryOrderDetails(v)">查看详情</van-button>

                  <template v-if="v.paid == true">
                    <van-button type="primary" round size="small" v-if="v.isRefund == false"
                      @click="applyShouHou(v)">申请售后</van-button>
                  </template>

                  <template v-if="!v.paid == true">
                    <van-button type="danger" round size="small" @click="confirmPayment(v)">立即付款</van-button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </van-list>

        <van-empty v-if="orderLists.length <= 0" description="您还没有订单~" />
      </van-tab>
    </van-tabs>


    <!-- 查看物流信息弹框 -->
    <van-popup v-model="logisticsStatus" closeable position="bottom" :style="{ height: '80%' }">
      <div style="padding: 10px">
        <van-form v-if="orderDetails">
          <van-field v-model="orderDetails.serialNum" name="订单号" label="订单号" placeholder="订单号" />

          <template v-if="orderDetails.orderFormDelivery">
            <van-field v-model="orderDetails.orderFormDelivery.deliveryCompanyName" name="快递公司" label="快递公司"
              placeholder="快递公司" />

            <van-field v-model="orderDetails.orderFormDelivery.deliverySerialNum" name="快递单号" label="快递单号"
              placeholder="快递单号" />
          </template>
        </van-form>

        <van-divider dashed>物流信息</van-divider>

        <van-steps direction="vertical" :active="0" v-if="logisticsList.length > 0">
          <van-step v-for="(item, index) in logisticsList" :key="index">
            <h3>{{ item.status }}</h3>
            <p>{{ item.time }}</p>
          </van-step>
        </van-steps>

        <van-empty description="暂无物流信息" v-else />
      </div>
    </van-popup>

    <OrderShouHou ref="orderShouHou" :orderShouHouId="this.orderShouHouId"
      :orderShouHouProductId="this.orderShouHouProductId" :orderShouHouQuantity="this.orderShouHouQuantity">
    </OrderShouHou>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
// import "@/assets/css/style.css";
import { Toast } from "vant";
import { getToken } from "@/utils/auth";

import { queryLogisticsInfo } from "@/api/order";

import OrderShouHou from "./orderShouHou.vue";

export default {
  name: "HelloWorld",
  components: {
    OrderShouHou,
  },
  data() {
    return {
      orderShouHouId: undefined,
      orderShouHouProductId: undefined,
      orderShouHouQuantity: undefined,
      logisticsList: [],
      orderDetails: undefined,
      logisticsStatus: false,

      loginStatus: true,
      showPicker: false,
      orderLists: [],
      card: [],
      cdKey: "",
      cdKeyStatus: false,
      orderActive: 0,
      dictList: [],
      orderList: [],
      queryParam: {
        keywordSearch: undefined,
        statusDelivery: undefined,
        pageNum: 1,
        pageSize: 10,
      },
      orderId: "",
      desc: "",
      value: "",
      code: "",
      productId: "",
      arr: [],
      options: [
        { value: 1, label: "充值没到账" },
        { value: 2, label: "其他问题" },
      ],

      loadingState: false,
      finished: false,
      isFetching: false, // 是否有正在进行中的请求

      orderStatusList: [
        {
          statusDelivery: "undefined",
          orderLabel: "全部",
        },
        {
          statusDelivery: "0",
          orderLabel: "等待处理",
        },
        {
          statusDelivery: "1",
          orderLabel: "正在处理",
        },
        {
          statusDelivery: "2",
          orderLabel: "交易成功",
        },
        {
          statusDelivery: "4",
          orderLabel: "已退款",
        },
      ],
      activeName: this.$route.query.statusDelivery,
    };
  },
  computed: {
    ...mapGetters({
      list: "orderList",
    }),
    ...mapState({
      list: (state) => state.order.orderList,
    }),
  },
  created() {
    this.queryParam.statusDelivery = this.$route.query.statusDelivery;

    //查询订单
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 获取物流信息
     */
    getLogisticsInfo(data) {
      this.logisticsList = [];

      this.orderDetails = data;

      let id = {
        id: data.id,
      };

      new Promise((resolve, rejust) => {
        queryLogisticsInfo(id)
          .then((res) => {
            this.logisticsList = res.result.list;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });

      this.logisticsStatus = true;
    },

    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/login");
      }
      this.loadData();
    },

    /**
     * 搜索查询
     */
    searchAction() {
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.orderLists = [];
      this.loadData();
    },

    /**
     * 加载更多商品
     */
    loadMore() {
      if (this.isFetching) {
        // 如果有正在进行中的请求，则不继续发起新的请求
        return;
      }
      this.queryParam.pageNum = this.queryParam.pageNum + 1;
      this.loadingState = true;

      this.loadData();
    },

    /**
     * 初始化接口
     */
    loadData() {
      this.isFetching = true;
      this.$store.dispatch("order/list", this.queryParam).then(() => {
        this.orderLists = this.orderLists.concat(this.list);
        if (this.list.length == 0) {
          this.finished = true;
        }
        this.loadingState = false;
        this.isFetching = false;
      });
    },

    /**
     * 切换tab页
     */
    changeTab(statusDelivery) {
      if (statusDelivery === "undefined") {
        statusDelivery = undefined;
      }
      this.orderLists = [];
      // 更新查询参数
      this.queryParam.statusDelivery = statusDelivery;
      this.queryParam.pageNum = 1;
      this.loadingState = true;
      this.finished = false;
      // 重新加载数据
      this.loadData();
    },

    onConfirm(value) {
      console.log(value);
      this.value = value.label;
      this.code = value.value;
      this.showPicker = false;
    },

    /**
     * 确认付款
     */
    confirmPayment(item) {
      this.$router.push({
        path: "/payment",
        query: { orderId: item.id },
      });
    },

    /**
     * 申请售后催促
     */
    cuichu() {
      Toast("已加急处理");
    },
    // // 查询商品列表
    // getDictList() {
    //   this.queryParam.dictType = 'order_status'
    //   dictList(this.queryParam).then((response) => {
    //     this.dictList = response.data.rows
    //   })
    // },

    // queryOrderList(value, k){
    //   this.orderActive = k
    //   orderInfoByType().then((response) => {
    //     this.orderList = response.rows
    //   })
    // },

    // /**
    //  * 查询卡密
    //  */
    // queryCdkey(v){
    //       this.cdKeyStatus = true
    //       this.card = JSON.parse(v.card)
    //   // outerOrder(this.queryParam).then((response) => {
    //   //   //数据加载
    //   //   console.log(response.data.data)
    //   //   if(response.data.data!=''){
    //   //     this.cdKey = response.data.data
    //   //   }
    //   // })
    // },

    //  //复制卡号
    // cardCopy(){
    //       const inputNode = document.createElement('input')
    //       inputNode.setAttribute('readonly', 'readonly')
    //       inputNode.value = this.card.cardNo
    //       document.body.appendChild(inputNode)
    //       inputNode.select()
    //       document.execCommand('Copy')
    //       inputNode.className = 'oInput'
    //       inputNode.style.display = 'none'
    //       Toast('卡号复制成功')
    // },

    // //复制密码
    // passWordCopy(){
    //       const inputNode = document.createElement('input')
    //       inputNode.setAttribute('readonly', 'readonly')
    //       inputNode.value = this.card.cardPassword
    //       document.body.appendChild(inputNode)
    //       inputNode.select()
    //       document.execCommand('Copy')
    //       inputNode.className = 'oInput'
    //       inputNode.style.display = 'none'
    //       Toast('卡密复制成功')
    // },

    // //复制Ckd
    // copyCkd(){
    //    var copyInput = document.createElement('input')
    //   //val是要复制的内容
    //   copyInput.setAttribute('readonly', 'readonly')
    //   copyInput.setAttribute('value', this.cdKey)
    //   document.body.appendChild(copyInput)
    //   copyInput.select()
    //   try {
    //     var copyed = document.execCommand('copy')
    //     if (copyed) {
    //       document.body.removeChild(copyInput)
    //       Toast('复制成功')
    //     }
    //   } catch {
    //       Toast.fail('复制失败,请检查客服')
    //   }
    // },

    /**
     * 跳转到商品详情
     */
    toProductDetails(id) {
      this.$router.push(`/product_detail/${id}`);
    
    },

    // /**
    // 查询订单详情
    //  */
    queryOrderDetails(v) {
      console.log(v);
      this.$router.push({
        path: "/order_detail",
        query: {
          orderId: v.id,
        },
      });
    },

    /**
     * 申请订单售后
     */
    applyShouHou(v) {
      this.orderShouHouId = v.id;
      this.orderShouHouProductId = v.orderFormProduct.id;
      this.orderShouHouQuantity = v.orderFormProduct.quantity;

      this.$refs.orderShouHou.handleOpen();
    },

  
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>
<style>
.my-order {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 23.4375rem;
  height: 100vh;
  background-color: #fff;
  overflow: auto;
}

.my-order .content-box {
  width: 23.4375rem;
  background-color: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-order .scroll-box {
  width: 23.4375rem;
  height: calc(100vh - 6.25rem);
  position: relative;
  background: #f5f7fa;
  margin-top: 0.625rem;
}

.uni-scroll-div {
  position: relative;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.uni-scroll-div {
  position: relative;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.uni-scroll-div-content {
  width: 100%;
  height: 100%;
}

.my-order .scroll-box .scroll-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0 0.5625rem;
  gap: 0.9375rem;
}

.scroll-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  /* width: 22.3125rem; */
  /* height: 12.8125rem; */
  background: #ffffff;
  border-radius: 0.46875rem;
  padding: 0px 10px 15px 10px;
  margin-top: 10px;
}

.scroll-item .item-top {
  margin-top: 0.53125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.scroll-item .item-top .order-text {
  color: #9b9fa8;
  font-weight: 400;
  font-size: 14px;
}

.copy-icon {
  width: 0.53125rem;
  height: 0.53125rem;
  position: relative;
  display: inline-block;
  border-color: #9b9fa8;
}

.copy-icon:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.09375rem;
  border: 0.0625rem solid;
  border-color: inherit;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #fff;
}

.copy-icon {
  width: 0.53125rem;
  height: 0.53125rem;
  position: relative;
  display: inline-block;
  border-color: #9b9fa8;
}

.scroll-item .item-top .link {
  color: #fe5f4d;
  font-weight: 600;
  font-size: 14px;
}

.scroll-item .center {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%;
  gap: 0.625rem;
  margin-top: 1.09375rem;
}

.scroll-item .center .goods-img {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 0.46875rem;
}

.goods-img .goodsImg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

/* .goods-img img {
        -webkit-touch-callout: none;
        user-select: none;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    } */

.scroll-item .center .center-right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.scroll-item .center .center-right .center-title {
  font-weight: 550;
  color: #1e2331;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 12.5rem;
}

.scroll-item .center .center-right .center-buy-info {
  margin-top: 0.71875rem;
  color: #9b9fa8;
  font-weight: 400;
  font-size: 0.75rem;
}

.scroll-item .center .center-right .center-num {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  color: #1e2331;
  font-weight: 400;
  font-size: 12px;
}

.scroll-item .center .center-right .center-num .number {
  color: #9b9fa8;
  font-size: 0.8125rem;
}

.scroll-item .order-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.03125rem;
  height: 2rem;
}

.scroll-item .order-bottom .order-bottom-left {
  font-weight: 400;
  color: #1e2331;
  font-size: 0.875rem;
}

.scroll-item .order-bottom .order-bottom-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
}

.scroll-item .order-bottom .order-bottom-right .info {
  /* background-color: #fff;
  border: 0.0625rem solid #a0a3ac;
  color: #a0a3ac; */
  background-color: #e11919;
  border: 0.0625rem solid #a0a3ac;
  color: #ffffff;
}

.scroll-item .order-bottom .order-bottom-right .order-btn {
  width: 5.25rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
}

.scroll-item .order-bottom .order-bottom-right .submit {
  color: #fff;
  background: linear-gradient(90deg, #fe8754 0%, #fe5f4d 100%);
}

.scroll-item .order-bottom .order-bottom-right .btn {
  width: 5.25rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 400;
}

.scroll-item .order-bottom .order-bottom-left .money {
  color: #fd4d20;
  font-weight: 550;
  font-size: 1.0625rem;
}
</style>
