import { render, staticRenderFns } from "./orderDetail.vue?vue&type=template&id=4ed52871&scoped=true"
import script from "./orderDetail.vue?vue&type=script&lang=js"
export * from "./orderDetail.vue?vue&type=script&lang=js"
import style0 from "./orderDetail.vue?vue&type=style&index=0&id=4ed52871&prod&scoped=true&lang=css"
import style1 from "./orderDetail.vue?vue&type=style&index=1&id=4ed52871&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed52871",
  null
  
)

export default component.exports